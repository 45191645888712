import React from 'react';

import Icon from './Icon';

export default function GooglePlayIcon({
  className = '',
  text = 'GooglePlay',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3315 6.07465L6.17132 2.00547C4.87317 1.2676 3.26318 2.20651 3.26318 3.69832V16.3017C3.26318 17.7935 4.87315 18.7324 6.17132 17.9945L13.3383 13.922C13.3628 13.9099 13.3867 13.8963 13.4101 13.8812L17.2609 11.6931C18.5748 10.9473 18.5748 9.05348 17.2609 8.30773L13.4165 6.12295C13.389 6.10482 13.3606 6.08873 13.3315 6.07465ZM11.8644 6.96619L6.32953 3.82068L10.3714 8.80871L11.8644 6.96619ZM4.76318 4.27026V15.7297L9.40609 9.99999L4.76318 4.27026ZM6.32945 16.1794L11.8647 13.0341L10.3714 11.1913L6.32945 16.1794ZM13.1866 12.2829L16.5202 10.3887C16.8213 10.2178 16.8216 9.78316 16.5205 9.61224L13.1863 7.71744L11.3367 9.99999L13.1866 12.2829Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
