import React from 'react';

import Icon from './Icon';

export default function FeedbackIcon({
  className = '',
  text = 'Feedback',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.9772 7.17011C3.8169 7.8914 3.75 8.82888 3.75 10.0592V11.9408C3.75 14.2663 3.99772 15.4752 4.55083 16.158C5.06719 16.7954 6.00838 17.1504 8.02999 17.2313L8.75 17.2601V19.9823L12.323 17.25H14.9408C16.1711 17.25 17.1086 17.1831 17.8299 17.0228C18.5427 16.8644 18.9975 16.6249 19.3112 16.3112C19.6249 15.9975 19.8644 15.5427 20.0228 14.8299C20.1831 14.1086 20.25 13.1711 20.25 11.9408V10.0592C20.25 8.82888 20.1831 7.8914 20.0228 7.17011C19.8644 6.45733 19.6249 6.00247 19.3112 5.68877C18.9975 5.37508 18.5427 5.13561 17.8299 4.9772C17.1086 4.8169 16.1711 4.75 14.9408 4.75H9.0592C7.82888 4.75 6.8914 4.8169 6.17011 4.9772C5.45733 5.13561 5.00247 5.37508 4.68877 5.68877C4.37508 6.00247 4.13561 6.45733 3.9772 7.17011ZM5.84469 3.51293C6.72728 3.31678 7.79464 3.25 9.0592 3.25H14.9408C16.2054 3.25 17.2727 3.31678 18.1553 3.51293C19.0464 3.71097 19.7945 4.05072 20.3719 4.62811C20.9493 5.2055 21.289 5.95357 21.4871 6.84469C21.6832 7.72728 21.75 8.79464 21.75 10.0592V11.9408C21.75 13.2054 21.6832 14.2727 21.4871 15.1553C21.289 16.0464 20.9493 16.7945 20.3719 17.3719C19.7945 17.9493 19.0464 18.289 18.1553 18.4871C17.2727 18.6832 16.2054 18.75 14.9408 18.75H12.8308L9.25931 21.4812C8.4368 22.1101 7.25 21.5237 7.25 20.4882V18.6876C5.54139 18.5499 4.23231 18.1477 3.38529 17.1022C2.45969 15.9596 2.25 14.2302 2.25 11.9408V10.0592C2.25 8.79464 2.31678 7.72728 2.51293 6.84469C2.71097 5.95357 3.05072 5.2055 3.62811 4.62811C4.2055 4.05072 4.95357 3.71097 5.84469 3.51293Z"
          fill="currentColor"
        />
        <path
          d="M9 7.78417C9.41421 7.78417 9.75 8.11995 9.75 8.53417V10.0459C9.75 10.4601 9.41421 10.7959 9 10.7959C8.58579 10.7959 8.25 10.4601 8.25 10.0459V8.53417C8.25 8.11995 8.58579 7.78417 9 7.78417Z"
          fill="currentColor"
        />
        <path
          d="M15 7.78417C15.4142 7.78417 15.75 8.11995 15.75 8.53417V10.0459C15.75 10.4601 15.4142 10.7959 15 10.7959C14.5858 10.7959 14.25 10.4601 14.25 10.0459V8.53417C14.25 8.11995 14.5858 7.78417 15 7.78417Z"
          fill="currentColor"
        />
        <path
          d="M8.52849 12.4678C8.24105 12.1821 7.77652 12.1761 7.48181 12.4578C7.18236 12.744 7.17161 13.2187 7.4578 13.5182L8 13C7.4578 13.5182 7.45862 13.519 7.45862 13.519L7.45945 13.5199L7.46119 13.5217L7.46497 13.5256L7.47369 13.5344C7.48003 13.5407 7.48742 13.5479 7.4959 13.5559C7.51286 13.572 7.53415 13.5913 7.56006 13.6134C7.61191 13.6577 7.68204 13.7129 7.77262 13.7749C7.95408 13.8993 8.21567 14.0499 8.57387 14.1951C9.29137 14.4859 10.3832 14.75 11.9779 14.75C13.5728 14.75 14.6738 14.4859 15.3997 14.1968C15.762 14.0525 16.0277 13.9033 16.2118 13.7808C16.3038 13.7196 16.375 13.6654 16.4275 13.6222C16.4538 13.6006 16.4753 13.5818 16.4924 13.5664C16.5009 13.5586 16.5083 13.5517 16.5146 13.5457L16.5233 13.5373L16.527 13.5336L16.5287 13.5319L16.5295 13.5311C16.5295 13.5311 16.5303 13.5303 16.0156 13.0156L16.5303 13.5303C16.8232 13.2374 16.8232 12.7626 16.5303 12.4697C16.2416 12.1809 15.7759 12.1768 15.4822 12.4575C15.4822 12.4575 15.4782 12.4609 15.4747 12.4637C15.4607 12.4752 15.4302 12.4991 15.3812 12.5317C15.2835 12.5967 15.1102 12.6975 14.8447 12.8032C14.3151 13.0141 13.4051 13.25 11.9779 13.25C10.5504 13.25 9.65329 13.0141 9.1374 12.8049C8.87889 12.7001 8.71267 12.6007 8.62049 12.5376C8.57424 12.5059 8.5461 12.4829 8.53388 12.4725C8.53149 12.4705 8.52849 12.4678 8.52849 12.4678Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
