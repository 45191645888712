import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ANDROID_APP_URL, FAQ_DOC_URL, IOS_APP_URL } from '../../../../shared/constants.platform';
import { openFeedbackSubMenu, openShortcutsSubmenu } from '../../../../shared/foreground/cmdPalette';
import { setEmptyStateCategory } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/lists';
import { getFaqDocument } from '../../../../shared/foreground/utils/getFaqDocument';
import { AnyDocument, Category, DocumentLocation } from '../../../../shared/types';
import { isDocumentWithEmptyStateInstructionsSidebar } from '../../../../shared/typeValidators';
import urlJoin from '../../../../shared/utils/urlJoin';
import { openURL } from '../../utils/openURL';
import Button from '../Button';
import AppleIcon from '../icons/20SolidApple';
import GooglePlayIcon from '../icons/20StrokeLogoGooglePlay';
import BrowserExtensionIcon from '../icons/24StrokeBrowserExtension';
import FeedbackIcon from '../icons/24StrokeFeedback';
import HelpIcon from '../icons/24StrokeHelp';
import HelpCircleIcon from '../icons/24StrokeHelpCircle';
import ImportIcon from '../icons/24StrokeImport';
import KeyboardIcon from '../icons/24StrokeKeyboard';
import AppropriateDesktopAppIcon from '../icons/AppropriateDesktopAppIcon';
import Tooltip from '../Tooltip';
import { focusPaletteInput, getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './HelpDropdown.module.css';

export default function HelpDropdown({
  triggerClassName = '',
  currentDoc,
}: { triggerClassName?: string; currentDoc?: AnyDocument }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const onHelpClick = useCallback(() => {
    const isFeed =
      currentDoc &&
      currentDoc.triage_status === DocumentLocation.Feed &&
      currentDoc.category === Category.RSS;

    if (isFeed) {
      setEmptyStateCategory(Category.RSS, { userInteraction: 'unknown' });
    } else if (currentDoc && isDocumentWithEmptyStateInstructionsSidebar(currentDoc)) {
      setEmptyStateCategory(currentDoc.category, { userInteraction: 'unknown' });
    }
  }, [currentDoc]);

  const navigateToResources = useCallback(() => history.push('/resources'), [history]);

  const options = useMemo(
    () => [
      {
        type: DropdownOptionType.Item,
        name: 'Help documentation',
        icon: <HelpCircleIcon />,
        onSelect: async () => {
          const faqDoc = await getFaqDocument();
          if (faqDoc) {
            history.push(urlJoin(['/read', faqDoc.id]));
          } else {
            // If user deleted helpdoc, open it in a new tab
            openURL(FAQ_DOC_URL, '_blank');
          }
        },
      },
      {
        type: DropdownOptionType.Item,
        name: 'Support and feedback',
        icon: <FeedbackIcon />,
        onSelect: () => {
          openFeedbackSubMenu();
          focusPaletteInput();
        },
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        name: 'Keyboard shortcuts',
        icon: <KeyboardIcon />,
        shortcut: '?',
        onSelect: () => {
          openShortcutsSubmenu();
        },
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        name: 'Show getting started tips',
        icon: <ImportIcon />,
        onSelect: onHelpClick,
        isDisabled: !currentDoc,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Get browser extension',
        icon: <BrowserExtensionIcon />,
        onSelect: navigateToResources,
      },
      {
        type: DropdownOptionType.Item,
        name: 'Get desktop app',
        icon: <AppropriateDesktopAppIcon className={styles.desktopAppIcon} text="" />,
        onSelect: navigateToResources,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Node,
        node: (
          <div className={styles.appLinksWrapper}>
            <a href={IOS_APP_URL} target="_blank" rel="noopener noreferrer">
              <AppleIcon />
              App store
            </a>
            <a href={ANDROID_APP_URL} target="_blank" rel="noopener noreferrer">
              <GooglePlayIcon />
              Play store
            </a>
          </div>
        ),
      },
    ],
    [history, navigateToResources, onHelpClick, currentDoc],
  );

  return (
    <Dropdown
      appendToDocumentBody
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      side="top"
      contentAlignment="end"
      contentClassName={styles.dropdownContent}
      trigger={
        <Tooltip disabled={isOpen} content="" placement="top-start">
          <DropdownMenu.Trigger asChild>
            <Button
              className={`${triggerClassName} ${styles.triggerElement} ${isOpen ? styles.isOpen : ''}`}
              variant="unstyled"
            >
              <HelpIcon />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
      }
    />
  );
}
