import React from 'react';

import Icon from './Icon';

export default function WindowsIcon({
  text = 'Windows',
  className = '',
}: { text?: string; className?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3947 27.296L6.728 24.1853C5.44267 23.9707 4.5 22.8587 4.5 21.5547V10.4453C4.5 9.14133 5.44267 8.02933 6.728 7.81467L25.3947 4.704C27.02 4.43333 28.5 5.68667 28.5 7.33333V24.6667C28.5 26.3133 27.02 27.5667 25.3947 27.296Z"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 6.52002V25.48"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 15.8133H28.5"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
}
